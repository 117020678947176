import React, { useState } from 'react';
import axios from 'axios';

const ApiTester = () => {
    const [inputUrl, setInputUrl] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setResponse(null);

        try {
            // Make a GET request to the provided URL
            const res = await axios.get(inputUrl);
            setResponse(res.data);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div>
            <h2>API Tester</h2>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    value={inputUrl} 
                    onChange={(e) => setInputUrl(e.target.value)} 
                    placeholder="Enter API endpoint URL" 
                    required 
                />
                <button type="submit">Test API</button>
            </form>
            {response && (
                <div>
                    <h3>Response:</h3>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                </div>
            )}
            {error && (
                <div>
                    <h3>Error:</h3>
                    <pre>{error}</pre>
                </div>
            )}
        </div>
    );
};

export default ApiTester;
